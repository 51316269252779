// Host
import { regtoolHost, regHost, iHost } from '@/assets/js/host';
// Axios
import { get } from '@/assets/js/axios';

/**
 * 数字缩减
 * @param {Number} _n 需要转换的数字
 * @param {Boolean} _pn 转换结果是否带符号 默认不带
 * @param {Number} _tf 转换后保留小数位 默认保留2位
 * @returns
 */
export function numUnit(_n = 0, _pn = false, _tf = 2) {
    let _num = Math.abs(_n), _str = _num;
    if (_num > 1000000) {
        _str = `${(_num / 10000).toFixed(_tf)}万`;
    }
    if (_num > 100000000) {
        _str = `${(_num / 100000000).toFixed(_tf)}亿`;
    }
    if (_num > 100000000000) {
        _str = `${(_num / 100000000000).toFixed(_tf)}千亿`;
    }
    return `${_n < 0 ? '-' : _pn ? '+' : ''}${_str}`;
}
/**
 * 画图区用数字缩减
 * @param {*} _n
 * @param {*} _pn
 * @param {*} _tf
 * @returns
 */
export function numUnitChart(_n = 0, _pn = false, _tf = 2) {
    let _num = Math.abs(_n),
        _str = _num;
    if (_num > 10000) {
        _str = `${(_num / 10000).toFixed(2)}万`;
    }
    if (_num > 100000) {
        _str = `${(_num / 100000).toFixed(_tf)}十万`;
    }
    if (_num > 1000000) {
        _str = `${(_num / 1000000).toFixed(_tf)}百万`;
    }
    if (_num > 10000000) {
        _str = `${(_num / 10000000).toFixed(_tf)}千万`;
    }
    if (_num > 100000000) {
        _str = `${(_num / 100000000).toFixed(_tf)}亿`;
    }
    /* if (_num > 1000000000) {
      _str = `${(_num / 1000000000).toFixed(_tf)}十亿`;
    }
    if (_num > 10000000000) {
      _str = `${(_num / 10000000000).toFixed(_tf)}百亿`;
    }
    if (_num > 1000000000) {
        _str = `${(_num / 100000000).toFixed(_tf)}亿`;
    } */
    return `${_n < 0 ? '-' : _pn ? '+' : ''}${_str}`;
}
/**
 * 检测用户登录
 *
 * 调用：
 * getUserInfo(res => {});
 *
 * 参数说明：
 * callback = 回调函数(object)。
 */
export function getUserInfo(callback) {
    const userToken = cookies.get('userToken');
    get(`${regtoolHost}/wapreg/checklogin.aspx`, {
        format: 'json',
        encode: 'utf-8'
    }).then(res => {
        res.nickname = decodeURIComponent(res.nickname);
        res.islogin = res.islogin === 'True' ? true : false;
        callback && callback(res);
    }).catch(err => {
        console.log(err);
        callback && callback({
            islogin: userToken ? true : false,
        });
    });
};

/**
 * 登录
 *
 * 调用：
 * goToLogin(goFrom, goUrl);
 *
 * 参数说明：
 * goFrom = 来源；
 * goUrl = H5登录后跳转地址，不传返回起始地址/APP直接返回起始地址。
 */
export function goToLogin(goFrom, goUrl) {
    if (window.javatojs) {
        // 来源
        const from = goFrom || '';
        if (from !== '') {
            window.javatojs.login(from);
        } else {
            window.javatojs.goToLogin();
        }
    } else {
        const url = goUrl || location.href;
        location.href = `${regHost}/h5/login.aspx?gourl=${encodeURIComponent(url)}`;
    }
};

/**
 * 登出
 *
 * 调用：
 * goToLogout(goUrl);
 *
 * 参数说明：
 * goUrl = H录后跳转地址，不传返回起始地址。
 */
export function goToLogout(goUrl) {
    const url = goUrl || location.href;
    location.href = `${regHost}/sso/logout.html?gourl=${encodeURIComponent(url)}`;
};

/**
 * 检测是否绑定手机号
 *
 * 调用：
 * checkMobile(callback);
 *
 * 参数说明：
 * callback = 回调函数
 */
export function checkMobile(callback) {
    get(`${regtoolHost}/wapreg/checkbindmobile.aspx`).then(res => {
        callback && callback(res);
    }).catch(err => {
        callback && callback(err);
    });
};

/**
 * 绑定手机号
 *
 * 调用：
 * bindMobile(from);
 *
 * 参数说明：
 * from = 来源
 */
export function bindMobile(gofrom) {
    if (window.javatojs && window.javatojs.toBindPhoneNumber) {
        const from = gofrom || 'H5';
        window.javatojs.toBindPhoneNumber(from);
    } else {
        location.href = `${iHost}/newhome/set/mobile`;
    }
};

/**
 * User-Agent
 *
 * 调用：
 * ua.type;
 *
 * 参数说明：
 * key = 查询属性名。
 */
export const appEnv = (function ua() {
    const ua = navigator.userAgent.toLowerCase();
    return {
        /* os */
        isiOS: ua.indexOf('iphone') > -1 || ua.indexOf('ipad') > -1 || ua.indexOf('ios') > -1,
        isAndroid: ua.indexOf('android') > -1 || ua.indexOf('adr') > -1 || ua.indexOf('linux;') > -1,
        /* browser */
        isQQ: ua.indexOf('qq/') > -1,
        isqqbrowser: ua.indexOf('mqqbrowser/') > -1,
        isUC: ua.indexOf('ucbrowser/') > -1 || ua.indexOf('ucweb/') > -1,
        isWechat: ua.indexOf('micromessenger/') > -1,
        isSamsung: ua.indexOf('samsungbrowser/') > -1,
        isSogou: ua.indexOf('sogoumobilebrowser/') > -1,
        isPinganWifi: ua.indexOf('pawifi') > -1,
        isChrome: ua.indexOf('chrome') > -1,
        isOpera: ua.indexOf('opera') > -1 || ua.indexOf('opr') > -1,
        isFirefox: ua.indexOf('firefox') > -1 || ua.indexOf('fxios') > -1,
        isBaiduboxapp: ua.indexOf('baiduboxapp/') > -1,
        isBaidubrowser: ua.indexOf('baidubrowser/') > -1,
        isQihoobrowser: ua.indexOf('qihoobrowser/') > -1,
        isMxios: ua.indexOf('mxios/') > -1,
        isTimMobile: ua.indexOf('tim/') > -1,
        isQHApp: ua.indexOf('hxappid=1052') > -1 || ua.indexOf('hxappid=1051') > -1,
        //财经app:1003=android,1006=ios
        isCJApp: ua.indexOf('hxappid=1003') > -1 || ua.indexOf('hxappid=1006') > -1,
        //财道app:1091=android,1092=ios
        isCDApp: ua.indexOf('hxappid=1092') > -1 || ua.indexOf('hxappid=1091') > -1,
        isHXApp: ua.indexOf('hxappid') > -1,
        isWeiBo: ua.indexOf('weibo') > -1,
        isMiuiBrowser: ua.indexOf('miuibrowser/') > -1,
        /* model */
        isIphoneX: /iphone[\s\S]*os x/.test(ua) && screen.height === 812 && screen.width === 375,
        isHUAWEI: /huawei/.test(ua),
        isOPPO: /oppo/.test(ua),
        isMEIZU: /meizu/.test(ua),
        isXIAOMI: /xiaomi/.test(ua) || /miuibrowser\//.test(ua) || /mi/.test(ua),
        isVIVO: /vivo/.test(ua),
        isREDMI: /redmi/.test(ua),
        isHONORBLN: /honorbln/.test(ua),
        isSAMSUNG: /sm\-/.test(ua) || /samsung/.test(ua),
        isLE: /Le\s+/.test(ua),
        isONEPLUS: /oneplus/.test(ua),
        isDOOV: /doov/.test(ua),
        isNBY: /nx[0-9]+/.test(ua),
        isLG: /lg\-/.test(ua),
        isOD: /od[0-9]+/.test(ua),
        isANE: /ane\-/.test(ua),
        isZUK: /zuk\s+/.test(ua),
        isLenovo: /lenovo/.test(ua),
        isMobile: !!ua.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
            || ua?.match('mobile') || (ua?.match('iph') && !ua?.match('ipad')) || ua?.match('480') || ua?.match('hxappid')
            || ua?.match('pad') || ua?.match('nexus 7')
            //680宽以下视为h5
            || window.document.documentElement.clientWidth <= 680,
        //账户测试用
        isUserTest: (window.location.host.match('test') && process.env.VUE_APP_ENV !== 'production')
    };
})();

/**
 * cookie存储
 *
 * 调用：
 * cookies.set(key, value, time);
 * cookies.get(key);
 * cookies.remove(key);
 *
 * 参数说明：
 * key = 属性名(string)；
 * value = 属性值(string)；
 * time = 有效时间(string, 's1' = 1秒 'h1' = 1小时 'd1' = 1天 'm1' = 1月 'y1' = 1年)。
 */
export const cookies = {
    /**
     * 设置cookie
     * @param {string} key
     * @param {string} value
     * @param {string} time
     */
    set(key, value, time) {
        const exp = new Date();
        const getsec = function (str) {
            const str1 = str.substring(1, str.length) * 1;
            const str2 = str.substring(0, 1);
            if (str2 === 's') {
                return str1 * 1000;
            } else if (str2 === 'h') {
                return str1 * 60 * 60 * 1000;
            } else if (str2 === 'd') {
                return str1 * 24 * 60 * 60 * 1000;
            } else if (str2 === 'm') {
                return str1 * 31 * 24 * 60 * 60 * 1000;
            } else if (str2 === 'y') {
                return str1 * 365 * 24 * 60 * 60 * 1000;
            } else {
                return 0;
            }
        };
        const strsec = getsec(time);
        exp.setTime(exp.getTime() + strsec * 1);
        document.cookie = `${key}=${value};expires=${exp.toGMTString()}`;
    },
    /**
     * 获取cookie
     * @param {string} key
     * @returns
     */
    get(key) {
        const reg = new RegExp(`(^| )${key}=([^;]*)(;|$)`);
        const arr = document.cookie.match(reg);
        if (arr) {
            return (arr[2]);
        } else {
            return null;
        }
    },
    /**
     * 移除cookie
     * @param {string} key
     */
    remove(key) {
        cookies.set(key, '', 's-1');
    }
};

/**
 * localStorage本地存储
 *
 * 调用：
 * localStorages.set(key, value);
 * localStorages.get(key);
 * localStorages.getRaw(key);
 * localStorages.remove(key);
 * localStorages.clear();
 *
 * 参数说明：
 * key = 属性名(string)；
 * value = 属性值(string)。
 */
export const localStorages = {
    set(key, value) { // 添加本地存储
        if (!key) {
            return;
        }
        if (typeof (value) == 'object') {
            localStorage.setItem(key, JSON.stringify(value));
        } else {
            localStorage.setItem(key, value);
        }
    },
    get(key) { // 获取对象格式的本地存储
        return JSON.parse(localStorage.getItem(key));
    },
    getRaw(key) { // 获取原格式的本地存储
        return localStorage.getItem(key) || null;
    },
    remove(key) { // 清除指定Key的存储
        localStorage.removeItem(key);
    },
    clear() { // 清除所有存储
        localStorage.clear();
    }
};

/**
 * @精度计算
 *
 * @加
 * count.add(number1, number2)
 * @减
 * count.sub(number1, number2)
 * @乘
 * count.mul(number1, number2)
 * @除
 * count.div(number1, number2)
 * @进制
 * count.hex(number, hex, fix)
 * @单位
 * count.unit(number, fix)
 *
 * @参数说明
 * number/number1/number2 = 数值
 * hex = 进制数(100/1000)
 * fix = 保留小数位(2)
 */
export const count = {
    /**
     * 加
     * @param {number} a
     * @param {number} b
     * @returns
     */
    add: (a, b) => {
        let c, d, e;
        try {
            c = a.toString().split('.')[1].length;
        } catch (f) {
            c = 0;
        }
        try {
            d = b.toString().split('.')[1].length;
        } catch (f) {
            d = 0;
        }
        return e = Math.pow(10, Math.max(c, d)), (count.mul(a, e) + count.mul(b, e)) / e;
    },
    /**
     * 减
     * @param {number} a
     * @param {number} b
     * @returns
     */
    sub: (a, b) => {
        let c, d, e;
        try {
            c = a.toString().split('.')[1].length;
        } catch (f) {
            c = 0;
        }
        try {
            d = b.toString().split('.')[1].length;
        } catch (f) {
            d = 0;
        }
        return e = Math.pow(10, Math.max(c, d)), (count.mul(a, e) - count.mul(b, e)) / e;
    },
    /**
     * 乘
     * @param {number} a
     * @param {number} b
     * @returns
     */
    mul: (a, b) => {
        const d = a.toString(), e = b.toString();
        let c = 0;
        try {
            c += d.split('.')[1].length;
        } catch (f) { }
        try {
            c += e.split('.')[1].length;
        } catch (f) { }
        return Number(d.replace('.', '')) * Number(e.replace('.', '')) / Math.pow(10, c);
    },
    /**
     * 除
     * @param {number} a
     * @param {number} b
     * @returns
     */
    div: (a, b) => {
        let c, d, e = 0, f = 0;
        try {
            e = a.toString().split('.')[1].length;
        } catch (g) { }
        try {
            f = b.toString().split('.')[1].length;
        } catch (g) { }
        if (b === 0) {
            return 0;
        }
        return c = Number(a.toString().replace('.', '')), d = Number(b.toString().replace('.', '')), count.mul(c / d, Math.pow(10, f - e));
    },
    /**
     * 进制
     * @param {number} a
     * @param {number} b
     * @param {number} f
     * @returns
     */
    hex: (a, b, f) => {
        const d = typeof a === 'string' ? parseFloat(a) : a;
        let c = 0;
        if (f !== undefined) {
            if (f === 0) {
                c = Math.floor(count.div(d, b));
            } else {
                c = count.div(d, b).toFixed(f);
            }
        } else {
            c = count.div(d, b);
        }
        return c;
    },
    /**
     * 单位
     * @param {number} a
     * @param {number} f
     * @returns
     */
    unit: (a, f) => {
        const m = Number(a), h = m < 0 ? '-' : '', k = 10000, sizes = ['', '万', '亿', '万亿'];
        let n = Math.abs(m), s;
        if (n >= k) {
            const i = Math.floor(count.div(Math.log(n), Math.log(k)));
            n = count.div(n, Math.pow(k, i));
            s = sizes[i];
        } else {
            s = sizes[0];
        }
        if (f) {
            n = n.toFixed(f);
        }
        return `${h}${n}${s}`;
    }
};

/**
 * 时间格式化
 * timeFormat(fmt, date)
 *
 * 参数说明
 * fmt = 格式规则(yyyy-MM-dd hh:mm:ss)
 * date = 时间戳
 *
 * 使用说明
 * 将Date转化为指定格式的String，
 * 月(M)、日(d)、小时(h)、分(m)、秒(s)、季度(q) 1-2个占位符，
 * 年(y) 1-4个占位符，
 * 毫秒(S) 1个占位符(1-3位的数字)
 *
 * timeFormat('yyyy-MM-dd hh:mm:ss.S', date) => 2021-01-27 08:09:04.423
 * timeFormat('yyyy-M-d h:m:s.S', date)      => 2021-1-27 8:9:4.18
 *
 */
export const timeFormat = (fmt, d) => {
    const date = new Date(d);
    const o = {
        // 月
        'M+': date.getMonth() + 1,
        // 日
        'd+': date.getDate(),
        // 时
        'h+': date.getHours(),
        // 分
        'm+': date.getMinutes(),
        // 秒
        's+': date.getSeconds(),
        // 季度
        'q+': Math.floor((date.getMonth() + 3) / 3),
        // 毫秒
        'S': date.getMilliseconds()
    };
    if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, (`${date.getFullYear()}`).substr(4 - RegExp.$1.length));
    }
    for (const k in o) {
        if (new RegExp(`(${k})`).test(fmt)) {
            fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : ((`00${o[k]}`).substr((`${o[k]}`).length)));
        }
    }
    return fmt;
};

/**
 * 易盾验证
 *
 * 初始化：参数 (绑定id)
 * checkYiDun.init('#captchanode');
 *
 * 调起：参数 (成功回调)
 * checkYiDun.verify(code => {
 *  console.log(code) // 易盾验证码
 * });
 *
 * 刷新：
 * checkYiDun.refresh();
 */
export const checkYiDun = {
    // 实例
    captchaIns: null,
    // 回调
    callback: null,
    // 配置
    config(element) {
        const t = this;
        return {
            element,
            captchaId: '9ea61be328c24a2ea7f506ff70254675',
            mode: 'bind',
            width: '300px',
            onReady(instance) {
                t.captchaIns = instance;
            },
            onVerify(err, data) {
                if (err) {
                    return;
                }
                t.callback && t.callback(data.validate);
            }
        };
    },
    // 初始化
    init(obj) {
        const t = this;
        initNECaptcha && initNECaptcha(t.config(obj), instance => {
            t.captchaIns = instance;
        }, err => {
            console.log(err);
            t.captchaIns = null;
        });
    },
    // 调起
    verify(callback) {
        checkYiDun.captchaIns.verify();
        const t = this;
        t.callback = callback;
    },
    // 刷新
    refresh() {
        checkYiDun.captchaIns.refresh();
    }
};
