import Vue from 'vue';
import VueRouter from 'vue-router';
import { appEnv } from '@/assets/js/utils';
Vue.use(VueRouter);

const isMobile = appEnv.isMobile;
const path = isMobile ? 'h5' : 'pc';
const routes = [
  {
    path: '/',
    name: 'index',
    component: () => import(`@/pages/${path}/appMain`),
    meta: {
      title: '期货大赛'
    },
    children: [{
      path: '',
      alias: 'home',
      name: 'home',
      component: () => import(`@/pages/${path}/home`),
      meta: { title: '首页' }
    }, {
      path: 'details',
      name: 'details',
      component: () => import(`@/pages/${path}/details`),
      meta: { title: '期货<br>大赛' }
    }, {
      path: 'prize',
      name: 'prize',
      component: () => import(`@/pages/${path}/prize`),
      meta: { title: '奖项<br>设置' }
    }, {
      path: 'rank',
      name: 'rank',
      component: () => import(`@/pages/${path}/rank`),
      meta: { title: '排行<br>榜' }
    }, {
      path: 'statistics',
      name: 'statistics',
      component: () => import(`@/pages/${path}/statistics`),
      meta: { title: '大赛<br>统计' }
    }, {
      path: 'user',
      name: 'user',
      // 不在菜单里显示
      alwaysShow: 'no',
      // component: () => import(`@/views/${path === 'pc' ? 'pc' : 'm'}/user`),
      component: () => import(`@/pages/${path}/user/index.vue`),
      meta: { title: '个人中心' }
    }, {
      path: 'agreement',
      name: 'agreement',
      // 不在菜单里显示
      alwaysShow: 'no',
      component: () => import(`@/views/${path === 'pc' ? 'pc' : 'm'}/agreement`),
      meta: { title: '期货实盘大赛用户协议' }
    },
    {
      path: 'signup',
      name: 'signup',
      alwaysShow: 'no',
      component: () => {
        if (path === 'pc') {
          return import(`@/pages/${path}/home`);
        } else {
          return import(`@/views/m/signup`);
        }
      },
      meta: {
        title: '绑定保证中心交易账号报名参赛'
      }
    }, {
      path: 'company',
      name: 'company',
      alwaysShow: 'no',
      component: () => {
        if (path === 'pc') {
          return import(`@/pages/${path}/home`);
        } else {
          return import(`@/pages/h5/company`);
        }
      },
      meta: {
        title: '绑定保证中心交易账号报名参赛'
      }
    }, {
      path: '*',
      name: '404',
      alwaysShow: 'no',
      redirect: '/',
      // component: () => import(`@/pages/${path}/home`),
      meta: { title: '首页' }
    }]
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  }
});

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = (routes[0].meta.title == to.meta.title ? to.meta.title : `${routes[0].meta.title}-${to.meta.title}`).replace(/<.*?>/ig, '');
  }
  if (to.matched.length) {
    next();
  } else {
    next('/');
  }
});

export default { router };
