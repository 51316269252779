
import Vue from 'vue';
import App from './App.vue';
import routerObj from './router';
import './assets/less/base.less';
import './assets/js/config';
import { getUserInfo, goToLogin, appEnv } from '@/assets/js/utils';
import { getEnrollTotal, getEnrollState } from '@/assets/js/api';
const { router } = routerObj,
    _global = {
        //是否为h5
        isMobile: Boolean(appEnv.isMobile),
        //是否为和讯app
        isHXApp: appEnv.isHXApp,
        //用户账号列表
        accountList: [],
        //当前用户数据
        userInfo: {},
        //登录
        login() {
            if (_global.isMobile) {
                goToLogin();
            } else {
                window?.popupLogin();
            }

        },
        //报名状态
        baoMingState: {
            //是否显示报名弹窗
            showForm: false,
            // 报名人数
            members: '0',
            // 报名状态
            enrollState: false,
            //是否打开华泰开户 //华泰开通交易金账号专用
            showHT: false,
            //当前保证金账号 重新绑定时专用
            originalAccount: ''
        },
        //打开报名弹窗
        /**
         *
         * @param {String} _oAid 需要重新绑定的账号
         */
        openBaoMing(_oAid = '') {
            if (_global.userInfo.islogin) {
                console.log('重新绑定0:', `- ${_oAid} -`, typeof _oAid);
                if (_oAid && typeof _oAid === 'string') _global.baoMingState.originalAccount = _oAid;
                if (appEnv.isHXApp && window?.javatojs?.toAppPage) {
                    window?.javatojs?.toAppPage(`${window.location.origin}/signup${_oAid && (`/${_oAid}`)}`);
                } else if (appEnv.isMobile) {
                    router.push({
                        name: 'signup'
                    });
                    // window.open(`${window.location.origin}/signup`);
                } else {
                    _global.baoMingState.showForm = true;
                }
            } else {
                _global.login();
            }
        },
        //华泰开户
        openHT() {
            if (_global.userInfo.islogin) {
                if (appEnv.isHXApp && window?.javatojs?.toAppPage) {
                    window?.javatojs?.toAppPage(`${window.location.origin}/company`);
                } else if (appEnv.isMobile) {
                    router.push({
                        name: 'company',
                    });
                } else {
                    _global.baoMingState.showHT = true;
                }
            } else {
                _global.login();
            }
        },
        //新开一页
        gotoWindow(_link = '') {
            if (_link) {
                if (appEnv.isHXApp && window?.javatojs?.toAppPage) {
                    window?.javatojs?.toAppPage(_link);
                }
                //h5
                else if (appEnv.isMobile) {
                    window.location.href = _link;
                }
                //pc
                else {
                    window.open(_link);
                }
            }
        },
        //统计数据
        situation: null,

    };
router.beforeEach((to, from, next) => {
    console.log('路由跳转时前一页:', from);
    //从报名页跳出时
    if (from?.path === '/signup') {
        // _global.baoMingState.showForm = false;
        Vue.set(_global.baoMingState, 'showForm', false);
        // Vue.set(_global.baoMingState, 'originalAccount', '');
    }
    if (to.matched.length) {
        next();
    } else {
        next('/');
    }
});
// Vue.config.productionTip = false;
if (process.env.VUE_APP_ENV !== 'production') {
    Vue.config.devtools = true;
}
Vue.prototype.$getGlobal = _global;
new Vue({
    data() {
        return {
            global: this.$getGlobal
        };
    }, watch: {
        'global.baoMingState.showForm'(_v) {
            if (!_v)
                this.$set(this.global.baoMingState, 'originalAccount', '');
        }
    },
    mounted() {
        // console.log('_this.$getGlobal=', this.$getGlobal);

        const _this = this;
        getUserInfo(res => {

            if (!res.photo) res.photo = 'https://test-logotool.hexun.com/52650fa-40.jpg';
            _this.$getGlobal.userInfo = res;
        });
        // 获取报名人数
        getEnrollTotal()
            .then(res => {
                const { data = 0 } = res;
                _this.$getGlobal.baoMingState.members = data.toString();
            })
            .catch(err => {
                console.log(err);
                _this.$getGlobal.baoMingState.members = '0';
            });

        // 获取报名状态
        getEnrollState()
            .then(res => {
                const { data = 0 } = res;
                if (data > 0) {
                    _this.$getGlobal.baoMingState.enrollState = true;
                }
            })
            .catch(err => {
                console.log(err);
            });

        //从报名页链接获取保证金账号id
        const _oAid = (window.location.href.match(/^https{0,1}:\/\/.*?signup\/(.*?)(\/|$)/i) || ['', ''])[1];
        if (this.$getGlobal.isMobile && _oAid) {
            this.$getGlobal.baoMingState.originalAccount = _oAid;
            this.$router.push({
                name: 'signup',
            });
        }


    },
    methods: {
    },
    router,
    render: h => h(App)
}).$mount('#app');
