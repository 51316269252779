/* 判断https */
const isHttps = 'https:' === document.location.protocol;
/* 获取环境 */
const env = process.env.VUE_APP_ENV;
/* 生产环境地址 */
let dsHost = 'https://ds.hexun.com';
const regtoolHost = '/regtoolHost'; // isHttps ? 'https://regtool.hexun.com' : 'http://reg.tool.hexun.com';
let regHost = 'https://reg.hexun.com';
const dsApiHost = '/dsApiHost'; // https://ds-api.hexun.com
const chartApiHost = '/chartApiHost';//http://test-futures-service.intcoop.hexun.com
let homewayHost = 'https://www.homeway.com.cn';
let iHost = 'https://i.hexun.com';
/* 非生产环境地址 */
if (env !== 'production') {
    dsHost = 'https://test-ds.hexun.com';
    // regtoolHost = isHttps ? 'https://test-regtool.hexun.com' : 'http://test-regtool.hexun.com';
    regHost = 'https://test-reg.hexun.com';
    // dsApiHost = 'https://test-ds-api.hexun.com';
    homewayHost = 'https://testcaidao.homeway.com.cn';
    iHost = 'https://test-i.hexun.com';
}
/* api */
export {
    dsHost,
    regtoolHost,
    regHost,
    dsApiHost,
    chartApiHost,
    homewayHost,
    iHost,
    env
};
