// Host
import { dsApiHost } from '@/assets/js/host';
// Axios
import { get, post } from '@/assets/js/axios';


/**
 * Banner广告拉取
 * @param {Number} _id=
 * PC首页：1
 * PC奖项设置：2
 * PC期货大赛：3
 * H5首页：4
 * H5奖项设置：5
 * H5期货大赛：6
 */
export const getBanner = (_id = 1) => {
    return new Promise((resolve, reject) => {
        get(`${dsApiHost}/api/banner/get?id=${_id}`).then(res => resolve(res)).catch(err => reject(err));
    });
};

/**
 * 获取报名人数
 */
export const getEnrollTotal = () => {
    return new Promise((resolve, reject) => {
        get(`${dsApiHost}/api/enroll/get-total`).then(res => resolve(res)).catch(err => reject(err));
    });
};

/**
 * 获取报名状态
 */
export const getEnrollState = () => {
    return new Promise((resolve, reject) => {
        get(`${dsApiHost}/api/enroll/get-enroll-state`).then(res => resolve(res)).catch(err => reject(err));
    });
};

/**
 * 获取个人信息
 */
export const getEnrollUserInfo = () => {
    return new Promise((resolve, reject) => {
        get(`${dsApiHost}/api/enroll/get-user-info`).then(res => resolve(res)).catch(err => reject(err));
    });
};

/**
 * 获取报名图形验证码
 */
export const getImageCode = () => {
    return new Promise((resolve, reject) => {
        get(`${dsApiHost}/api/enroll/get-image-code`).then(res => resolve(res)).catch(err => reject(err));
    });
};

/**
 * 提交报名信息
 *
 * @param data {object}：
 *  data.clientId {string} codeImage接口中返回的clientId
 *  data.imageCode {string} 验证码
 *  data.account {string} 保证金账户
 *  data.password {string} 保证金密码
 */
export const postEnter = data => {
    return new Promise((resolve, reject) => {
        post(`${dsApiHost}/api/enroll/enter`, data).then(res => resolve(res)).catch(err => reject(err));
    });
};

/**
 * 获取期货公司列表
 */
export const getCompany = () => {
    return new Promise((resolve, reject) => {
        get(`${dsApiHost}/api/retain/get-company`).then(res => resolve(res)).catch(err => reject(err));
    });
};

/**
 * 获取留资信息
 * @param data {object}：
 *  data.companyId {string} 公司ID
 */
export const getInfo = data => {
    return new Promise((resolve, reject) => {
        get(`${dsApiHost}/api/retain/get-info`, data).then(res => resolve(res)).catch(err => reject(err));
    });
};

/**
 * 发送验证码
 * @param data {object}：
 *  data.mobile {string} 手机号
 *  data.validate {string} 易盾验证码
 */
export const sendSms = data => {
    return new Promise((resolve, reject) => {
        get(`${dsApiHost}/api/retain/send-sms`, data).then(res => resolve(res)).catch(err => reject(err));
    });
};

/**
 * 提交留资信息
 * @param data {object}：
 *  data.mobile {string} 手机号
 *  data.code {string} 验证码
 *  data.realName {string} 真实姓名
 *  data.companyId {string} 公司ID
 */
export const submitInfo = data => {
    return new Promise((resolve, reject) => {
        get(`${dsApiHost}/api/retain/submit-info`, data).then(res => resolve(res)).catch(err => reject(err));
    });
};

/**
 * 初始化参数报名昵称
 */
export const initNick = (_aid = '') => {

    return new Promise((resolve, reject) => {
        get(`${dsApiHost}/api/enroll/init-nick${_aid ? `?aid=${_aid}` : ''}`).then(res => resolve(res)).catch(err => reject(err));
    });
};
