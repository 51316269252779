(function (doc, win) {
    // 安卓忽略字体设置
    const handleFontSize = () => {
        // 设置网页字体为默认大小
        WeixinJSBridge.invoke('setFontSizeCallback', {'fontSize': 0});
        // 重写设置网页字体大小的事件
        WeixinJSBridge.on('menu:setfont', () => {
            WeixinJSBridge.invoke('setFontSizeCallback', {'fontSize': 0});
        });
    }
    if (typeof WeixinJSBridge === 'object' && typeof WeixinJSBridge.invoke === 'function') {
        handleFontSize();
    } else {
        doc.addEventListener('WeixinJSBridgeReady', handleFontSize, false);
    }

    // rem适配设置
    const html = doc.documentElement;
    const resizeEvt = 'orientationchange' in win ? 'orientationchange' : 'resize';
    const recalc = () => {
        // 设置根data-dpr
        const dpr = win.devicePixelRatio || 1;
        html.setAttribute('data-dpr', dpr);
        // 设置根font-size
        const hWidth = html.clientWidth;
        if (!hWidth) {
            return;
        }
        html.style.fontSize = `${hWidth / 7.5}px`;
    };
    recalc();
    if (!doc.addEventListener) {
        return;
    }
    win.addEventListener(resizeEvt, recalc, false);
    doc.addEventListener('DOMContentLoaded', recalc, false);

    // 阻止双击放大
    let lastTouchEnd = 0;
    doc.addEventListener('touchstart', event => {
        if (event.touches.length > 1) {
            event.preventDefault();
        }
    });
    doc.addEventListener('touchend', event => {
        const now = (new Date()).getTime();
        if (now - lastTouchEnd <= 300 && event.cancelable) {
            event.preventDefault();
        }
        lastTouchEnd = now;
    }, false);

    // 阻止双指放大
    doc.addEventListener('gesturestart', event => {
        event.preventDefault();
    });

})(document, window);
