/**
 * @Axios
 */

import axios from 'axios';

// import { Message } from 'element-ui';
// 添加请求超时时间
axios.defaults.timeout = 10000;

// post请求头
axios.defaults.headers.post['Content-Type'] = 'application/json;charset=utf-8';
/**
 * @添加请求拦截器
 */
axios.interceptors.request.use(config => {
    /**
     * @在发送请求之前处理
     * 1.添加时间戳防止走缓存数据
     */
    if (config.method === 'get') {
        config.params = {
            timestamp: Date.parse(new Date()) / 1000,
            ...config.params
        };
    }
    return config;
}, error => {
    /**
     * @对请求错误处理
     */
    return Promise.reject(error);
});

/**
 * @添加响应拦截器
 */
axios.interceptors.response.use(response => {
    /**
     * @对响应数据处理
     * 1.研发部行情中心接口返回的特殊形式数据格式处理((json); => json)
     */
    const data = response.data;
    const regExp = /^\(.*\)\;$/g;
    try {
        if (typeof data === 'string' && regExp.test(data)) {
            response.data = JSON.parse(data.replace(/^\(|\)\;$/g, ''));
        }
    } catch (error) {
        console.log('responseERROR;', error);
    }
    return response;
}, error => {
    // for debug
    /**
     * @对响应错误处理
     */
    return Promise.reject(error);
});

/**
 * @封装get方法
 * url 请求url
 * params 请求参数
 */
export const get = (url, params = {}) => {
    return new Promise((resolve, reject) => {
        axios.get(url, {
            params,
        }).then(response => {
            resolve(response.data);
        }).catch(err => {
            console.log("网络错误:" + err);
            // reject(err);
            // Message.error("网络错误");
        });
    });
};

/**
 * @封装post请求
 * url 请求url
 * data 请求data
 */
export const post = (url, data) => {
    return new Promise((resolve, reject) => {
        axios.post(url, data).then(response => {
            resolve(response.data);
        }, err => {
            console.log("网络错误:" + err);
            // Message.error("网络错误");
            // reject(err);
        });
    });
};
